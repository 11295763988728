export const tiposIncidencia = [
  { label: 'Crédito', value: 0 },
  { label: 'Pagamento', value: 1 },
];

export const tiposPeriodoApuracao = [
  { label: 'Mensal', value: 0 },
  { label: 'Quinzenal', value: 1 },
  { label: 'Decendial', value: 2 },
  { label: 'Semanal', value: 3 },
];

export const tiposProdutor = [
  { label: 'Física', value: 0 },
  { label: 'Jurídica', value: 1 },
];
