import api from '~/services/api';

type responseData = {
  success: boolean;
  data?: any;
  message: string;
};

const retencoesTributariasApi = {
  getContaContabilId: async (cod_conta: number): Promise<responseData> => {
    try {
      const request = await api.get(`/contaContabilId/${cod_conta}`);
      const { success, message, data } = request.data;

      return {
        success,
        data,
        message,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message,
      };
    }
  },
  getCategorias: async (): Promise<responseData> => {
    try {
      const request = await api.get(`/categorias`);
      const { success, message, data } = request.data;

      return {
        success,
        data,
        message,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message,
      };
    }
  },
  getFinalizadora: async (): Promise<responseData> => {
    try {
      const request = await api.get(`/finalizadora`);
      const { success, message, data } = request.data;

      return {
        success,
        data,
        message,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message,
      };
    }
  },
  getContaBancaria: async (): Promise<responseData> => {
    try {
      const request = await api.get(`/contabancaria`);
      const { success, message, data } = request.data;

      return {
        success,
        data,
        message,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.message,
      };
    }
  },
};

export default retencoesTributariasApi;
