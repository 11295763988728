import React from 'react';
import { Container } from './styles';
import Search from '~/components/Search';
import { ReprocessaPisCofinsProvider } from './ReprocessaPisCofinsContext';
import ReprocessaPisCofinsContent from './ReprocessaPisCofinsContent';

const ReprocessaPisCofins: React.FC = () => {
  function defaultOnlyReport() {
    return false;
  }
  return (
    <ReprocessaPisCofinsProvider>
      <ReprocessaPisCofinsContent />
    </ReprocessaPisCofinsProvider>
  );
};

export default ReprocessaPisCofins;
