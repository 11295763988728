import React from 'react';
import { createContext, useContext } from 'react';
import { LojaContext } from '~/context/loja';
import {
  ReprocessaPisCofinsContextData,
  ReprocessaPisCofinsProviderProps,
} from './types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';

export const ReprocessaPisCofinsContext = createContext(
  {} as ReprocessaPisCofinsContextData,
);

export const ReprocessaPisCofinsProvider: React.FC<
  ReprocessaPisCofinsProviderProps
> = ({ children }) => {
  const { loja } = useContext(LojaContext);
  /**
   * Form ReprocessaPisCofins
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const valuesProvider = {
    loja,
    formReprocessaPisCofins: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState,
      clearErrors,
      handleSubmit,
      trigger,
    },
  };
  return (
    <ReprocessaPisCofinsContext.Provider value={valuesProvider}>
      {children}
    </ReprocessaPisCofinsContext.Provider>
  );
};

export const useReprocessaPisCofins = (): ReprocessaPisCofinsContextData => {
  return useContext(ReprocessaPisCofinsContext);
};
