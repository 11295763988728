import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  #DropDownWidth {
    .react-select__menu {
      position: absolute;
      width: 500px;
      z-index: 9999;
    }
  }

  .separator {
    border-right: 1px solid #ebecf0;
    display: flex;
    max-width: 1px;
    height: 35px;
    align-self: flex-end;
    margin-bottom: 3px;
    padding: 0px;
  }

  @media screen and (max-width: 991px) {
    .fixWidth {
      min-width: 150px !important;
    }
  }
  @media screen and (min-width: 150px) {
    .fixWidthCC {
      min-width: 500px !important;
    }
  }
`;
