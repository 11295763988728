import * as yup from 'yup';

export const schema = yup.object().shape({
  cod_loja: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  tipo_reprocessamento: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  tipo_parametro: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  dta_inicial: yup.string().required(),
  dta_final: yup.string().required(),
});
