import * as yup from 'yup';

export const schema = yup
  .object({
    des_retencao: yup.string().required(),
    cod_darf: yup.string().required(),
    per_aliq: yup.string().required(),
    tipo_apuracao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    per_red_bc: yup.string().required(),
    val_deduzir: yup.string().required(),

    cod_categoria: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_cc: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    qtd_dias_vencto: yup.number().test('custom-validation', function (value) {
      if (Number(value) <= 0) {
        return false;
      }

      return true;
    }),
    conta_contabil: yup.string().test('custom-validation', function (value) {
      if (Number(value) === 0 || value === '') {
        return false;
      }

      return true;
    }),

    tipo_inicidencia: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),

    val_min_bc: yup
      .string()
      .test(
        'custom-validation',
        'Este campo é obrigatório quando flg_acum_val_min é verdadeiro e val_min_retencao está vazio',
        function (value) {
          const { flg_acum_val_min, val_min_retencao } = this.parent;

          if (flg_acum_val_min && !val_min_retencao) {
            return !!value;
          }

          return true;
        },
      ),
    val_min_retencao: yup
      .string()
      .test(
        'custom-validation-val_min_retencao',
        'Este campo é obrigatório quando flg_acum_val_min é verdadeiro e val_min_bc está vazio',
        function (value) {
          const { flg_acum_val_min, val_min_bc } = this.parent;

          if (flg_acum_val_min && !val_min_bc) {
            return !!value;
          }

          return true;
        },
      ),
    tipo_produtor: yup.object().when('flg_produtor_rural', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
  })
  .required();
