import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Categoria from '~/components/Categoria';
import ContaContabil from '~/components/ContaContabil';
import FormDefault from '~/components/FormDefault';
import {
  InputMoney,
  InputNumber,
  InputPercent,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';

import {
  tiposIncidencia,
  tiposPeriodoApuracao,
  tiposProdutor,
} from './constants';
import { Container } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';
import { transformAsCurrency } from '~/utils/functions';
import retencoesTributariasApi from './services';
import { formatCurrency } from './function/formatCurrency';
import { Col, Row } from 'react-bootstrap';

const RetencoesTributarias: React.FC = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const flgAcumValMinWatch = watch('flg_acum_val_min');
  const flgRetencaoObraWatch = watch('flg_retencao_obra');
  const flgProdutorRuralWatch = watch('flg_produtor_rural');
  const flgAntPgtoWatch = watch('flg_ant_pgto');

  const [isShowSearch, setIsShowSearch] = useState<boolean>(true);
  const [contaContabilReduzida, setContaContabilReduzida] = useState<any>('');
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [codRetencao, setCodRetencao] = useState<number>();
  const [optionsCategoria, setOptionsCategoria] = useState<any>();
  const [categoriaSelect, setCategoriaSelect] = useState<any>();
  const [optionsFinalizadora, setOptionsFinalizadora] = useState<any>();
  const [optionsFinalizadoraAtivas, setOptionsFinalizadoraAtivas] =
    useState<any>();
  const [optionsConta, setOptionsConta] = useState<any>();
  const [optionsContaAtiva, setOptionsContaAtiva] = useState<any>();

  useEffect(() => {
    (async () => {
      const { data, success, message } =
        await retencoesTributariasApi.getCategorias();

      if (success) {
        if (message !== 'Nenhum registro encontrado.') {
          const options = data.map((categorias: any) => ({
            label: categorias.des_subcategoria,
            value: categorias.cod_subcategoria,
          }));
          setOptionsCategoria(options);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data, success, message } =
        await retencoesTributariasApi.getFinalizadora();

      if (success) {
        if (message !== 'Nenhum registro encontrado.') {
          const options = data.map((Finalizadoras: any) => ({
            label: Finalizadoras.des_finalizadora,
            value: Finalizadoras.cod_finalizadora,
          }));
          setOptionsFinalizadora(options);

          const opt = data.flatMap((f: any) => {
            if (f.flg_inativa !== true) {
              return [
                {
                  label: f.des_finalizadora,
                  value: f.cod_finalizadora,
                },
              ];
            }
            return [];
          });

          setOptionsFinalizadoraAtivas(opt);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data, success, message } =
        await retencoesTributariasApi.getContaBancaria();
      if (success) {
        if (message !== 'Nenhum registro encontrado.') {
          const options = data.map((conta: any) => ({
            label: conta.des_cc,
            value: conta.cod_cc,
          }));
          setOptionsConta(options);

          const optAtiva = data.flatMap((conta: any) => {
            if (conta.flg_inativa !== true) {
              return [
                {
                  label: conta.des_cc,
                  value: conta.cod_cc,
                },
              ];
            }
            return [];
          });

          setOptionsContaAtiva(optAtiva);
        }
      }
    })();
  }, []);

  const handleChangeTipoIncidencia = useCallback(
    (val: { label: string; value: number }) => {
      setValue('tipo_inicidencia', val);
    },
    [setValue],
  );

  const handleChangePeriodoApuracao = useCallback(
    (val: { label: string; value: number }) => {
      setValue('tipo_apuracao', val);
    },
    [setValue],
  );

  const handleChangeCategoria = useCallback(
    (val: { label: string; value: number }) => {
      setValue('cod_categoria', val);
    },
    [setValue],
  );

  const handleChangeFinalizadora = useCallback(
    (val: { label: string; value: number }) => {
      setValue('cod_finalizadora', val);
    },
    [setValue],
  );

  const handleChangeConta = useCallback(
    (val: { label: string; value: number }) => {
      setValue('cod_cc', val);
    },
    [setValue],
  );

  const handleTiposProdutor = useCallback(
    (val: { label: string; value: number }) => {
      setValue('tipo_produtor', val);
    },
    [setValue],
  );

  const handleBC = useCallback(
    (ev: any) => {
      clearErrors('val_min_retencao');
      const { val_min_retencao } = getValues();
      const inputValue = ev.target.value;
      const equal0 = val_min_retencao === '0,0';

      if (!val_min_retencao || equal0) {
        setValue('val_min_bc', inputValue);
      } else {
        setValue('val_min_bc', undefined);
        toast.warning(
          'Não é permitido informar Valor Mínimo de BC e Retenção. Apenas um valor Mínimo deve ser informado',
        );
      }
    },
    [setValue, clearErrors, getValues],
  );

  const handleRetencao = useCallback(
    (ev: any) => {
      clearErrors('val_min_bc');
      const inputValue = ev.target.value;
      const { val_min_bc } = getValues();
      const equal0 = val_min_bc === '0,0';

      if (!val_min_bc || equal0) {
        setValue('val_min_retencao', inputValue);
        clearErrors('val_min_retencao');
      } else {
        setValue('val_min_retencao', undefined);
        toast.warning(
          'Não é permitido informar Valor Mínimo de BC e Retenção. Apenas um valor Mínimo deve ser informado',
        );
      }
    },
    [setValue, clearErrors, getValues],
  );

  const handleClearForms = useCallback(() => {
    reset();
    setValue('per_red_bc', '');
    setValue('cod_finalizadora', {
      label: undefined,
      value: undefined,
    });
    setValue('cod_cc', { label: undefined, value: undefined });
    setValue('qtd_dias_vencto', '');
    setValue('per_aliq', '');
    setValue('cod_darf', '');
    setValue('des_retencao', '');
    setValue('tipo_inicidencia', { label: undefined, value: undefined });
    setValue('tipo_apuracao', { label: undefined, value: undefined });
    setValue('tipo_produtor', { label: undefined, value: undefined });
    setValue('val_deduzir', '');
    setValue('val_min_bc', '');
    setCategoriaSelect({ value: undefined, label: '' });

    setValue('flg_acum_val_min', false);
    setValue('flg_produtor_rural', false);
    setValue('flg_retencao_obra', false);
    setValue('flg_ant_pgto', false);
    setValue('val_min_retencao', '');
    setValue('conta_contabil', '');
    setContaContabilReduzida('');
    clearErrors();
  }, [reset, setValue, clearErrors]);

  const getConta = async (cod_conta: number) => {
    if (cod_conta) {
      const { data, success } =
        await retencoesTributariasApi.getContaContabilId(cod_conta);
      if (success && data.length > 0) {
        setContaContabilReduzida(data[0].conta_reduzida);
        setValue('cod_conta', cod_conta);
      }
    }
  };

  const onNewData = () => {
    setIsShowSearch(false);
    setIsUpdate(false);
    handleClearForms();
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setIsUpdate(true);
    setIsShowSearch(false);

    const {
      cod_retencao,
      des_retencao,
      cod_darf,
      per_aliq,
      tipo_apuracao,
      tipo_inicidencia,
      flg_acum_val_min,
      val_min_bc,
      val_min_retencao,
      per_red_bc,
      val_deduzir,
      flg_produtor_rural,
      tipo_produtor,
      flg_retencao_obra,
      cod_categoria,
      cod_finalizadora,
      cod_cc,
      qtd_dias_vencto,
      flg_ant_pgto,
      cod_conta,
    } = row;

    getConta(cod_conta);

    setCodRetencao(cod_retencao);

    const perAliqFormatted =
      per_aliq === '0'
        ? new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(0)
        : String(per_aliq).replace('.', ',');

    setValue('per_red_bc', String(per_red_bc).replace('.', ','));
    setValue('qtd_dias_vencto', qtd_dias_vencto);
    setValue('per_aliq', perAliqFormatted);
    setValue(
      'val_min_bc',
      val_min_bc === '0,00'
        ? undefined
        : formatCurrency(transformAsCurrency(val_min_bc), 2),
    );
    setValue(
      'val_min_retencao',
      val_min_retencao === '0,00'
        ? undefined
        : formatCurrency(transformAsCurrency(val_min_retencao), 2),
    );
    setValue('val_deduzir', formatCurrency(val_deduzir, 2));
    setValue(
      'tipo_inicidencia',
      tiposIncidencia.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === tipo_inicidencia;
        },
      ),
    );
    setValue(
      'tipo_apuracao',
      tiposPeriodoApuracao.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === tipo_apuracao;
        },
      ),
    );
    setValue(
      'tipo_produtor',
      flg_produtor_rural
        ? tiposProdutor.find(
            (op: { label: string | undefined; value: number | undefined }) => {
              return op.value === tipo_produtor;
            },
          )
        : { label: '', value: undefined },
    );
    setValue(
      'cod_finalizadora',
      optionsFinalizadora.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_finalizadora;
        },
      ),
    );
    setValue(
      'cod_cc',
      optionsConta.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_cc;
        },
      ),
    );

    setValue('des_retencao', des_retencao);
    setValue('cod_darf', cod_darf);
    setValue('flg_acum_val_min', flg_acum_val_min);
    setValue('flg_produtor_rural', flg_produtor_rural);
    setValue('flg_retencao_obra', flg_retencao_obra);
    setValue('flg_ant_pgto', flg_ant_pgto);

    setValue(
      'cod_categoria',
      optionsCategoria.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_categoria;
        },
      ),
    );

    setCategoriaSelect(
      optionsCategoria.find(
        (op: { label: string | undefined; value: number | undefined }) => {
          return op.value === cod_categoria;
        },
      ),
    );
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (dataForm.flg_produtor_rural && !dataForm.tipo_produtor) {
      return toast.warning('Necessário informar tipo do produtor.');
    }

    let valMinBcIsEmpty = false;
    let valMinRetencaoIsEmpty = false;

    if (dataForm.val_min_bc) {
      valMinBcIsEmpty =
        dataForm.val_min_bc.trim() === '' ||
        dataForm.val_min_bc.trim() === '0,0';
    }

    if (dataForm.val_min_retencao) {
      valMinRetencaoIsEmpty =
        dataForm.val_min_retencao.trim() === '' ||
        dataForm.val_min_retencao.trim() === '0,0';
    }

    if (dataForm.flg_acum_val_min && valMinBcIsEmpty && valMinRetencaoIsEmpty) {
      setError('val_min_bc', { type: 'required' });
      setError('val_min_retencao', { type: 'required' });

      return toast.warning('Informe um Valor Mínimo de BC ou Retenção.');
    }

    const datePrepare = {
      des_retencao: dataForm.des_retencao,
      cod_darf: dataForm.cod_darf,
      per_aliq: dataForm.per_aliq ? transformAsCurrency(dataForm.per_aliq) : 0,
      tipo_apuracao: dataForm.tipo_apuracao.value,
      tipo_inicidencia: dataForm.tipo_inicidencia.value,
      flg_acum_val_min: dataForm.flg_acum_val_min,
      val_min_bc: dataForm.val_min_bc
        ? transformAsCurrency(dataForm.val_min_bc)
        : null,
      val_min_retencao: dataForm.val_min_retencao
        ? transformAsCurrency(dataForm.val_min_retencao)
        : null,
      per_red_bc: dataForm.per_red_bc
        ? transformAsCurrency(dataForm.per_red_bc)
        : null,
      val_deduzir: dataForm.val_deduzir
        ? transformAsCurrency(dataForm.val_deduzir)
        : null,
      flg_produtor_rural: dataForm.flg_produtor_rural,
      tipo_produtor: dataForm.tipo_produtor.value,
      flg_retencao_obra: dataForm.flg_retencao_obra,
      cod_categoria: dataForm.cod_categoria.value,
      cod_finalizadora: dataForm.cod_finalizadora.value,
      cod_cc: dataForm.cod_cc.value,
      qtd_dias_vencto: dataForm.qtd_dias_vencto,
      flg_ant_pgto: dataForm.flg_ant_pgto,
      cod_conta: dataForm.conta_contabil,
    };

    try {
      if (isUpdate) {
        const { data } = await api.put(`/retencao/${codRetencao}`, {
          ...datePrepare,
        });
        if (data.success) {
          toast.success(data?.message);
          handleClearForms();
          setIsShowSearch(true);
          return;
        }
        if (data?.status === 422) {
          return toast.warning(data?.message);
        }
        toast.error(data?.message);
        return;
      }

      const { data } = await api.post('/retencao', {
        ...datePrepare,
      });

      if (data.success) {
        toast.success(data.message);
        handleClearForms();
        clearErrors();
        setIsShowSearch(false);
      }
    } catch (error) {
      console.log(error);
    }
  });

  function onCancel() {
    handleClearForms();
    setIsShowSearch(true);
  }

  const onDelete = async () => {
    try {
      const res = await api.delete(`/retencao/${codRetencao}`);
      const { success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      setIsShowSearch(true);
      handleClearForms();

      toast.success(message);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onClear = () => {
    handleClearForms();
  };

  return (
    <Container>
      {isShowSearch && (
        <Search codTela={83} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!isShowSearch && (
        <FormDefault
          codTela={83}
          title="Retenções Tributárias"
          codigoRegistro={[
            {
              value: codRetencao,
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          onCancel={onCancel}
          isUpdate={isUpdate}
          onNew={onNewData}
          onDelete={onDelete}
          onClearFields={onClear}
          onReturnSearch={onCancel}
        >
          <Row>
            <Col sm={12} md={8}>
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Digite aqui a descrição"
                name="des_retencao"
                register={register}
                control={control}
                caseInput="upper"
                disabled={isUpdate}
                isError={!!errors.des_retencao}
              />
            </Col>
            <Col sm={12} md={2} className="fixWidth">
              <InputNumber
                label="Cód. Guia Rec.: "
                maxLength={4}
                max={4}
                placeholder="0"
                name="cod_darf"
                register={register}
                control={control}
                disabled={isUpdate}
                isError={!!errors.cod_darf}
                onChange={(event: any) => {
                  if (event.target.value.length <= 4) {
                    setValue('cod_darf', Number(event.target.value));
                  }
                }}
              />
            </Col>
            <Col sm={12} md={2} className="fixWidth">
              <InputPercent
                label="Alíquota"
                maxLength={8}
                placeholder="0,00"
                name="per_aliq"
                register={register}
                control={control}
                max={100.0001}
                min={0}
                qtdDecimais={4}
                showIcon
                isError={!!errors.per_aliq}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <InputSelect
                label="Incidência"
                placeholder="Selecione a Incidência"
                name="tipo_inicidencia"
                register={register}
                options={tiposIncidencia}
                control={control}
                isError={!!errors.tipo_inicidencia}
                changeSelected={(newValue: any) => {
                  clearErrors('tipo_inicidencia');
                  handleChangeTipoIncidencia(newValue);
                }}
              />
            </Col>
            <Col sm={12} md={3}>
              <InputSelect
                label="Período de Apuração"
                placeholder="Selecione o Período"
                name="tipo_apuracao"
                register={register}
                options={tiposPeriodoApuracao}
                control={control}
                isError={!!errors.tipo_apuracao}
                changeSelected={(newValue: any) => {
                  clearErrors('tipo_apuracao');
                  handleChangePeriodoApuracao(newValue);
                }}
              />
            </Col>

            <Col
              sm={12}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '0.625rem',
                position: 'relative',
              }}
            >
              <div
                className="separator"
                style={{
                  position: 'absolute',
                  left: '20px',
                  bottom: 0,
                }}
              />
              <ToggleDefault
                labelText="Valor Mínimo?"
                setChecked={flgAcumValMinWatch}
                onSwitch={() => {
                  const flgAculVal = !flgAcumValMinWatch;
                  setValue('flg_acum_val_min', flgAculVal);
                  if (flgAculVal) {
                    toast.warning(
                      'Deve ser informado valor Mínimo para Base de Cálculo ou Valor Retenção',
                    );
                  } else {
                    setValue('val_min_bc', '');
                    setValue('val_min_retencao', '');
                  }
                }}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputMoney
                label="BC:"
                placeholder="0,00"
                min={0}
                maxLength={14}
                name="val_min_bc"
                showIcon
                disabled={!flgAcumValMinWatch || getValues('val_min_retencao')}
                control={control}
                register={register}
                isError={!!errors.val_min_bc}
                onInput={handleBC}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputMoney
                label="Retenção"
                placeholder="0,00"
                min={0}
                maxLength={14}
                name="val_min_retencao"
                showIcon
                disabled={!flgAcumValMinWatch || getValues('val_min_bc')}
                control={control}
                register={register}
                isError={!!errors.val_min_retencao}
                onInput={handleRetencao}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={2} className="fixWidth">
              <InputPercent
                label="% Redução BC"
                placeholder="0,00"
                max={1000.0}
                min={0}
                qtdDecimais={2}
                maxLength={6}
                name="per_red_bc"
                showIcon
                register={register}
                control={control}
                isError={!!errors.per_red_bc}
                onInput={(event: any) => {
                  setValue('per_red_bc', event.target.value.replace(',', '.'));
                }}
              />
            </Col>

            <Col sm={12} md={2} className="fixWidth">
              <InputMoney
                label="Val. Deduzir"
                placeholder="0,00"
                min={0}
                maxLength={14}
                name="val_deduzir"
                showIcon
                control={control}
                register={register}
                isError={!!errors.val_deduzir}
                onInput={(event: any) => {
                  setValue('val_deduzir', event.target.value);
                }}
              />
            </Col>

            <Col
              sm={12}
              md={2}
              style={{ textAlign: 'center', padding: '0.625rem' }}
            >
              <ToggleDefault
                labelText="Retenção de Obra?"
                setChecked={flgRetencaoObraWatch}
                onSwitch={() => {
                  setValue('flg_retencao_obra', !flgRetencaoObraWatch);
                }}
              />
            </Col>

            <Col
              sm={12}
              md={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <div
                className="separator"
                style={{
                  position: 'absolute',
                  left: '20px',
                  bottom: 0,
                }}
              />
              <ToggleDefault
                labelText="Produtor Rural?"
                setChecked={flgProdutorRuralWatch}
                onSwitch={() => {
                  setValue('flg_produtor_rural', !flgProdutorRuralWatch);
                  setValue('tipo_produtor', {
                    label: undefined,
                    value: undefined,
                  });

                  if (!flgProdutorRuralWatch === false) {
                    setValue('tipo_produtor', {
                      label: undefined,
                      value: undefined,
                    });
                  }

                  if (
                    !flgProdutorRuralWatch === false &&
                    getValues('tipo_produtor').value === undefined
                  )
                    return toast.warning(
                      'Tipo de Produtor Rural deve ser selecionado',
                    );
                }}
              />
            </Col>

            <Col sm={12} md={4}>
              <InputSelect
                label="Tipo de Produtor"
                placeholder="Selecione o Tipo"
                name="tipo_produtor"
                register={register}
                options={tiposProdutor}
                control={control}
                isError={!!errors.tipo_produtor}
                disabled={!flgProdutorRuralWatch}
                changeSelected={(newValue: any) => {
                  handleTiposProdutor(newValue);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <Separator labelText="Financeiro" />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3} id="DropDownWidth">
              <Categoria
                value={categoriaSelect}
                tipoCategoria="Despesa"
                isRequired
                setInvalid={!!errors.cod_categoria}
                iniInicializado={!!errors.cod_categoria}
                onChange={(newValue: any) => {
                  setCategoriaSelect(newValue);
                  handleChangeCategoria(newValue);
                }}
              />
            </Col>

            <Col sm={12} md={3}>
              <InputSelect
                label="Finalizadora"
                placeholder="Selecione a Finalizadora"
                name="cod_finalizadora"
                register={register}
                options={optionsFinalizadoraAtivas}
                control={control}
                isError={!!errors.cod_finalizadora}
                changeSelected={(newValue: any) => {
                  clearErrors('cod_finalizadora');
                  handleChangeFinalizadora(newValue);
                }}
              />
            </Col>

            <Col sm={12} md={2}>
              <InputSelect
                label="Conta"
                placeholder="Selecione a Conta"
                name="cod_cc"
                register={register}
                options={optionsContaAtiva}
                control={control}
                isError={!!errors.cod_cc}
                changeSelected={(newValue: any) => {
                  clearErrors('cod_cc');
                  handleChangeConta(newValue);
                }}
              />
            </Col>

            <Col sm={12} md={2} className="fixWidth">
              <InputNumber
                label="Qtd Dias de Vencimento"
                name="qtd_dias_vencto"
                max={9}
                maxLength={9}
                min={0}
                control={control}
                placeholder="0"
                register={register}
                onChange={(event: any) => {
                  setValue('qtd_dias_vencto', Number(event.target.value));
                }}
                isError={!!errors.qtd_dias_vencto}
              />
            </Col>

            <Col
              sm={12}
              md={2}
              style={{ textAlign: 'center', padding: '0.625rem' }}
            >
              <ToggleDefault
                labelText="Antecipa pagamento?"
                setChecked={flgAntPgtoWatch}
                onSwitch={() => {
                  setValue('flg_ant_pgto', !flgAntPgtoWatch);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className="fixWidthCC">
              <ContaContabil
                value={contaContabilReduzida}
                min={0}
                lengthBuscaConta={0}
                isRequired
                setInvalid={!!errors.conta_contabil}
                iniInicializado={!!errors.conta_contabil}
                isDisabled={false}
                focus={false}
                onChange={(
                  newValue: number,
                  isInvalid = true,
                  codConta = 0,
                ) => {
                  setContaContabilReduzida(newValue);
                  setValue('conta_contabil', codConta);
                }}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};
export default RetencoesTributarias;
